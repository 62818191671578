/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */

#Categorias {
  text-align: center;
}

.col-6-p {
  width: 50%;
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
}

.col-12-p {
  width: 100%;
  box-sizing: border-box;
  padding: 10vw;
  padding-right: 15px;
  padding-left: 15px;
}

#filaIndex {
  padding: 0.9rem;
  display: grid;
  grid-gap: 0.5rem;
  column-gap: 0.5rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(29rem, 1fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}
.centradoBoton {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  position: relative;
  font-weight: bold;
}
.centradoBotonIndex {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}
.centradoBotonConcurso {
  position: absolute;
  top: 30vw;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 1vw;
  font-size: 3vw;
}

.center {
  display: block;
  margin-top: 1rem;
}
#tituloCentrado {
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.carousel {
  background: #fff;
}

/*
Forces image to be 100% width and not max width of 100%
*/
.carousel-item {
  height: 70vh;
}
.img-fluid {
  width: 100%;
}

.carousel-caption {
  color: black !important;
}

/* 
anchors are inline so you need ot make them block to go full width
*/
.carousel-item a {
  display: block;
  width: 100%;
}

#Boton {
  background-color: black;
  border: black;
  padding: 1px;
  background-color: #000000;
  color: #ffffff;
  text-decoration: auto;
  text-transform: uppercase;
  backface-visibility: hidden;
  font-size: 36px;
  letter-spacing: 12px;
  /* width: 88%; */
}
.position {
  margin-top: -17% !important;
}

/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  .carousel-item {
    height: 60vh;
  }
  .carousel {
    background: #fff;
  }

  #filaIndex {
    display: grid;
    grid-gap: 1rem;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
  #Boton {
    background-color: black;
    border: black;
    padding: 1px;
    background-color: #000000;
    color: #ffffff;
    text-decoration: auto;
    text-transform: uppercase;
    backface-visibility: hidden;
    letter-spacing: 6px;
    /* width: 74%; */
    font-size: 34px;
  }
  .position {
    margin-top: -17% !important;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  .carousel-item {
    height: 60vh;
    margin: 0%;
  }
  .carousel {
    background: #fff;
  }

  #filaIndex {
    display: grid;
    grid-gap: 1rem;
    gap: 1em;
    column-gap: 1rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
  #Boton {
    background-color: black;
    border: black;
    background-color: #000000;
    color: #ffffff;
    text-decoration: auto;
    text-transform: uppercase;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    letter-spacing: 9px;
    /* width: 74%; */
    font-size: 26px;
  }
  .position {
    margin-top: -73% !important;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  .carousel-item {
    height: 50vh;
  }
  .carousel {
    background: #fff;
  }

  .col-6-p {
    width: 100%;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
  }

  #filaIndex {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    column-gap: 1rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
  #Boton {
    background-color: black;
    border: black;
    background-color: #000000;
    color: #ffffff;
    text-decoration: auto;
    text-transform: uppercase;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    letter-spacing: 3px;
    /* width: 71%; */
    font-size: 20px;
  }
  .position {
    margin-top: -76% !important;
  }
}

@media (max-width: 440px) {
  .carousel-item {
    height: 45vh;
  }

  #filaIndex {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    column-gap: 1rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
  #Boton {
    background-color: black;
    border: black;
    background-color: #000000;
    color: #ffffff;
    text-decoration: auto;
    text-transform: uppercase;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    letter-spacing: 3px;
    /* width: 71%; */
    font-size: 18px;
  }
  .position {
    margin-top: -76% !important;
  }
}

/*Ajuste link concurso */
@media (max-width: 1399px){
  .centradoBotonConcurso {
    top: 41vw;
  }
}

@media (max-width: 769px){
  .centradoBotonConcurso {
    top: 62vw;
  }
}

@media (max-width: 576px){
  .centradoBotonConcurso {
    top: 62vw;
  }
}
