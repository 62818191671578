.img-Modal {
  object-fit: contain;
  width: 20%;
  height: 20%;
  margin-top: 20px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
}

#productosCarro {
  position: absolute;
  overflow: auto;
  height: 220px;
  overflow-x: hidden;
}

#texto-Error {
  margin-top: 50px;
  width: 100%;
  font-weight: bold;
}

#texto-Error-agotado {
  margin-top: 50px;
  width: 100%;
  font-weight: bold;
  font-size: 2em;
}

#TituloError {
  font-size: 3em;
}

.botonCerrarModal {
  padding-top: 5px !important;
  margin-top: 50px !important;
  color: #000000 !important;
  font-size: 25px !important;
  border: solid 3px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
}

.botonCerrarModal:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.botonWhatsapp:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}
.botonWhatsapp {
  color: #000000 !important;
}

.list-group {
  max-height: 300px;
  margin-bottom: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: auto;
}

.noPadding {
  padding: 0 !important;
}

.noMargen {
  margin: 0 !important;
}

.LineaTotal {
  color: #000000;
  background-color: #000000;
  height: 1px;
  border-color: #000000;
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
}

.atributosTabla {
  font-weight: bold;
  margin-top: 50px;
}

.img-producto-pedido {
  width: 100px;
  height: 150px;
  margin-left: 0px;
  margin-right: 10px;
  padding-right: 0px;
}

.centrar {
  display: table;
  margin: auto;
}

.FuenteBebas {
  font-family: "Bebas";
}

.FuenteHelvetica {
  font-family: "Helvetica";
}
