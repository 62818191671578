/*Mas de 800px de ancho y menos de 1150px de ancho (Tipo: Pc, Web)*/
@media (max-width: 800px) and (min-width: 320px) {
  .listaProductos {
    width: 85vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .listaProductos .img-producto {
    width: 83vw;
    height: 70vh;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .listaProductos .carta {
    width: 83vw;
    height: 40vh;
    margin: 1vw;
  }

  .listaProductos .imgMedidas {
    width: 83vw;
    margin-bottom: 10px;
  }

  .listaProductos #noHayWebM {
    display: none;
  }

  .listaProductos #noHayMovilM {
    width: 85vw;
    margin-top: 10px;
  }

  .listaProductos .ImgNoHayMovilM {
    width: 85vw;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .listaProductos .ImgNoHayWebM {
    display: none;
  }

  .listaProductos #WebGrande {
    display: none;
  }

  .listaProductos .tipoCompra img {
    height: 25px;
    width: 25px;
    margin: 5px;
  }

  .listaProductos .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }

  .categoriaBar {
    font-size: 24px;
    margin-top: auto;
    margin-bottom: 0;
  }

  .LineaBar {
    margin-top: 20px;
    margin-top: 20px;
  }

  .flechaBars {
    position: absolute;
    right: -1%;
    top: 60px;
    transform: translate(-1%, -50%);
    height: 15px;
  }

  .relojContador {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-bottom: 20px;
  }

  .cuentaRegresiva {
    font-size: 20px;
  }

  .numeroReloj {
    color: black;
    background-color: white;
    padding: 5px 15px 5px 15px;
  }

  .contadorUnitario {
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: white;
  }

  .textContadorUnitario {
    font-size: 15px;
  }

}
