/*=========================
  Icons
 ================= */
* {
  margin: 0;
  padding: 0;
}
.cp-text {
  color: #000000;
  text-shadow: 0 1px rgba(255, 255, 255, 0.1);
  margin-top: 20px;
}

/* footer social icons */
.footer {
  background-color: #f8f9fa;
  padding-top: 10px;
  text-align: center;
}

ul.social-network {
  list-style: none;
  display: inline;
  margin: auto;
}
ul.social-network li {
  display: inline;
  margin: 0 10px;
}

/* footer social icons */
.social-network a.icoFacebook:hover {
  background-color: #3b5998;
}
.social-network a.icoTwitter:hover {
  background-color: #33ccff;
}
.social-network a.icoMedium:hover {
  background-color: #00ab6c;
}
.social-network a.icoQuora:hover {
  background-color: #aa2200;
}
.social-network a.icoLinkedin:hover {
  background-color: #007bb7;
}
.social-network a.icoInstagram:hover {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f09433", endColorstr="#bc1888", GradientType=1);
}

.social-network a.icoRss:hover i,
.social-network a.icoFacebook:hover i,
.social-network a.icoTwitter:hover i,
.social-network a.icoMedium:hover i,
.social-network a.icoVimeo:hover i,
.social-network a.icoLinkedin:hover i {
  color: #fff !important;
}

.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 22px;
}

.social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.social-circle li a:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.social-circle i {
  color: #fff !important;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.social-circle a {
  background-color: #000000;
}

@media screen and (max-width: 500px) {
  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }
}
@media screen and (max-width: 450px) {
  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }

  .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 16px;
  }

  .social-circle li i {
    margin: 0;
    line-height: 30px;
    text-align: center;
  }
}
@media screen and (max-width: 350px) {
  ul.social-network li {
    display: inline;
    margin: 0 2px;
  }

  .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 16px;
  }

  .social-circle li i {
    margin: 0;
    line-height: 30px;
    text-align: center;
  }
}
