.MercadoMayoristas {
  height: 85vh;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
}

.mensajeAnuncio {
  margin-bottom: 150px;
  font-weight: bold;
  font-size: 15px;
  width: 200px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
}

@font-face {
  font-family: "HelveticaBold";
  src: url("../../../../../static/Fonts/HelveticaBold.otf");
  font-display: swap;
}

.FuenteHelveticaBold {
  font-family: "HelveticaBold";
}

.imagenCategoria {
  width: 95px;
  height: 95px;
  margin-right: 20px;
}

.Bar {
  margin-top: 20px;
  width: 85vw;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
}

.barLinea {
  display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.Descuento {
  color: red;
  font-size: 1.2rem;
  font-family: "HelveticaBold";
  position: absolute;
  right: 2%;
  top: 40%;
}

div.vertical-line {
  width: 1px;
  background-color: black;
  height: 95px;
  float: left;
  z-index: 100;
  margin-right: 20px;
}

.LineaBar {
  color: #000000;
  background-color: #000000;
  height: 1;
  border-color: #000000;
  margin-bottom: 0px;
  margin-top: 1.5em;
  border: solid 2px #e9ecef
}

.flechaBar {
  margin-top: 3px;
  width: 1.2rem;
  margin-left: 15px
}

.productosDespliegue {
  transition: all 200ms ease-out;
}

.flechaGrandeAbajo {
  position: absolute;
  left: 50%;
  margin-top: 0px;
  transform: translate(-50%, 0%);
  -webkit-animation: movement 1.5s linear infinite;
  animation: movement 1.5s linear infinite;
}

.newProduct {
  color: blue;
  position: absolute;
  transform: translate(-25%, 30px);
  font-weight: bold;

}

@keyframes movement {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0px;
  }
}
@-webkit-keyframes movement {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0px;
  }
}

.dropdown-slidedown {
  max-height: 100%;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}

#noHayWebMayoristas {
  margin-top: 30px;
  margin-bottom: 30px;
}

#noHayMovilMayoristas {
  display: none;
}

.cuentaRegresiva {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#circle span {
  position: fixed;
  width: 10px;
  height: 40px;
  bottom: 49px;
  right: 40px;
  text-align: center;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  transform-origin: bottom center;
}

.float {
  position: fixed;
  width: 51px;
  height: 51px;
  bottom: 24px;
  right: 19px;
  background-color: #25d366;
  color: #fff;
  border-radius: 33px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
}
.float:hover {
  text-decoration: none;
  color: #25d366;
  background-color: #fff;
}

.my-float {
  margin-top: 11px;
}

/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */

#filaMayorita {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
  gap: 5rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}
/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  #filaMayorita {
    width: 100%;
    padding-top: 1rem;
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-gap: 1rem;
    gap: 2rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  #filaMayorita {
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    gap: 4rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  #filaMayorita {
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    gap: 2rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}
