/*Mas de 800px de ancho y menos de 1150px de ancho (Tipo: Pc, Web)*/
.imgMedidas {
  width: 100%;
  margin-bottom: 10px;
}
#productos {
  width: 90vw;
  height: auto;
  top: 15%;
  left: 5%;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-left: 3%; */
}
@media (min-width: 800px) and (max-width: 1055px) {
  /* #productos {
    width: 90vw;
    height: auto;
    top: 10%;
    left: 5%;
    overflow-y: auto;
    overflow-x: hidden;

  } */

  .img-producto {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .carta {
    width: 45vw;
    height: 40vh;
    margin-left: 15px;
    padding-left: 15px;
  }
  /* 
  .imgMedidas {
    width: 45vw;
    margin-bottom: 10px;
  } */

  #noHayWeb {
    width: 70vw;
    height: 80vh;
    position: absolute;
    left: 7%;
  }
  #noHayMovil {
    display: none;
  }

  .ImgNoHayWeb {
    position: relative;
    width: 120%;
    height: 90%;
  }

  #WebGrande {
    display: none;
  }

  .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }
}

/*Mas de 600px de ancho y mas de 850px de alto (Tipo: Pc, Web)*/
@media (min-width: 1055px) {
  .img-producto {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }

  .carta {
    width: 24.3vw;
    height: 40vh;
    margin: 1vw;
  }

  /* .imgMedidas {
    width: 24.3vw;
    margin-bottom: 20px;
  } */

  #noHayWeb {
    width: 70vw;
    height: 80vh;
    position: absolute;
    left: 7%;
  }

  #noHayMovil {
    display: none;
  }

  #filtros {
    position: fixed;
    top: 12%;
    left: -1%;
    width: 18vw;
    height: 88%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ImgNoHayWeb {
    position: relative;
    width: 100%;
    height: 90%;
  }

  #WebPequeña {
    display: none;
  }

  .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }
}
