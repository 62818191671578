#tallas {
  position: relative;
  top: 0px;
  text-align: center;
}

#talla {
  font-size: 20px;
}

#Linea {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 28px 30px;
  background-repeat: no-repeat;
  background-size: 100% 4px;
  color: black;
}

.imgTalla {
  height: 1.8em;
  width: 1.8em;
  border-radius: 50%;
  margin-right: 10px;
}

.SinDecoracion {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  outline: none;
}

.SinDecoracion:focus {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  outline: none;
}

.FuenteBebas {
  font-family: "Bebas";
}

.FuenteHelvetica {
  font-family: "Helvetica";
}

.NoHayMas {
  margin-left: 30px;
  margin-top: 5px;
}

.btn {
  margin: 15px;
  padding: 5px;
}

[type="radio"] {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  padding: 10px;
  margin: -5px -20px -5px -5px;
}

#contentedor select option {
  display: inline-block;
}

#contentedor {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}

.btnCantidad {
  font-size: 0.6rem;
  line-height: 15px;
  background: black;
  color: white;
  padding: 6px !important;
}

@media (max-width: 480px) {
  .imgTalla {
    height: 1.3em;
    width: 1.3em;
    border-radius: 50%;
    margin-right: 2px;
  }

  .btnCantidad {
    font-size: 0.6rem;
    line-height: 15px;
    background: black;
    color: white;
    padding: 0.5px !important;
  }
}
