.promo-main {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 10;
  margin-top: 10rem;
  flex-direction: column;
  align-items: center;
}

.promo-main div {  
  position: fixed;
  width: 22rem;
  display: flex;
  justify-content: flex-end;
}

.promo-main button {  
  padding: 0.5em;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
}

@media (max-width: 400px) {
  .promo-main {
    margin-top: 10vh;
  }
}