/*Mas de 800px de ancho y menos de 1150px de ancho (Tipo: Pc, Web)*/
@media (min-width: 800px) and (max-width: 1055px) {
  .listaProductos {
    width: 85vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .listaProductos .img-producto {
    width: 36.5vw;
    height: 500px;
    object-fit: contain;
  }

  .listaProductos .carta {
    width: 36.5vw;
    height: 40vh;
    margin: 1vw;
  }

  .listaProductos .imgMedidas {
    width: 36.5vw;
    margin-bottom: 10px;
  }

  .listaProductos #noHayWebM {
    width: 85vw;
    height: 80vh;
  }

  .listaProductos #noHayMovilM {
    display: none;
  }

  .listaProductos .ImgNoHayWebM {
    width: 120%;
    height: 90%;
  }

  .listaProductos #WebGrande {
    display: none;
  }

  .listaProductos .tipoCompra img {
    height: 25px;
    width: 25px;
    margin: 5px;
  }

  .listaProductos .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }

  .categoriaBar {
    font-size: 1.6rem;
    margin-bottom: 0px
  }

  .relojContador {
    max-width: 500px;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-bottom: 20px;
  }

  .cuentaRegresiva {
    font-size: 30px;
    border: 2px solid;
    background-color: red;
  }

  .numeroReloj {
    color: black;
    background-color: white;
    padding: 5px 15px 5px 15px;
  }

  .contadorUnitario {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    color: white;
  }

  .textContadorUnitario {
    font-size: 20px;
  }
}

/*Mas de 600px de ancho y mas de 850px de alto (Tipo: Pc, Web)*/
@media (min-width: 1055px) {
  .listaProductos {
    width: 85vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .listaProductos .img-producto {
    width: 26vw;
    height: 500px;
    object-fit: contain;
  }

  .listaProductos .carta {
    width: 26vw;
    height: 40vh;
    margin: 1vw;
  }

  .listaProductos .imgMedidas {
    width: 25vw;
    margin-bottom: 20px;
  }

  .listaProductos #noHayWebM {
    width: 85vw;
    height: 80vh;
  }

  .listaProductos .ImgNoHayWebM {
    width: 85vw;
    height: 80vh;
    object-fit: contain;
  }

  .listaProductos #noHayMovilM {
    display: none;
  }
  .listaProductos #WebPequeña {
    display: none;
  }

  .listaProductos .tipoCompra img {
    height: 25px;
    width: 25px;
    margin: 5px;
  }

  .listaProductos .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }

  .categoriaBar {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-bottom: 0px
  }

  .relojContador {
    max-width: 500px;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-bottom: 20px;
  }

  .cuentaRegresiva {
    font-size: 30px;
    border: 2px solid;
    background-color: red;
  }

  .numeroReloj {
    color: black;
    background-color: white;
    padding: 5px 15px 5px 15px;
  }

  .contadorUnitario {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    color: white;
  }

  .textContadorUnitario {
    font-size: 20px;
  }
}
