.FuenteBebas {
  font-family: "Bebas";
}
.FuenteHelvetica {
  font-family: "Helvetica";
}

.FuentePoppinsB {
  font-family: "Poppins-Bold";
}

.FuentePoppinsL {
  font-family: "Poppins-Light";
  font-weight: 600;
}

.FuentePoppinsItalicB {
 font-family: "Poppins-SemiBoldItalic";
}

input[type="radio"]:after {
  width: 15px !important;
  height: 15px !important;
  border-radius: 15px !important;
  top: -2px !important;
  left: -1px !important;
  position: relative !important;
  background-color: #d1d3d1 !important;
  content: "" !important;
  display: inline-block !important;
  visibility: visible !important;
  border: 2px solid white !important;
}

input[type="radio"]:checked:after {
  width: 17px !important;
  height: 17px !important;
  border-radius: 15px !important;
  top: -2px !important;
  left: -1px !important;
  position: relative !important;
  background-color: #000000 !important;
  content: "" !important;
  display: inline-block !important;
  visibility: visible !important;
  border: 2px solid white !important;
}

#genero {
  text-decoration: none;
  outline: none;
  padding: 0;
  border: none;
  background: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 4px;
  transition: background-size 0.3s;
  font-size: 1.2rem;
  color: black;
  background-color: #ffffff00;
}

#genero:hover,
#genero:focus {
  background-size: 100% 4px;
}

.categorias {
  outline: none;
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 4px;
  transition: background-size 0.3s;
  font-size: 1rem;
  color: black;
  background-color: #ffffff00;
}

.SinDecoracionFiltros {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  text-decoration: none;
  outline: none;
}

.SinDecoracionFiltros:focus {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  text-decoration: none;
  outline: none;
}

.marcas {
  outline: none;
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 4px;
  transition: background-size 0.3s;
  font-size: 1rem;
  color: black;
  background-color: #ffffff00;
  outline: none;
  text-decoration: none;
  padding: 0;
  border: 0;
}

.marcas:focus {
  outline: none;
  text-decoration: none;
  padding: 0;
  border: 0;
}

#tallas {
  text-decoration: none;
  font-size: 20px;
  color: black;
  margin: 0;
}

.radio-filtros input[type="radio"] {
  margin-top: 10px;
  border-radius: 15px;
  width: 15px;
  height: 15px;
  content: "";
}

.radio-filtros input[type="radio"]:after {
  margin-top: 10px;
  border-radius: 17px;
  position: relative;
  width: 16px;
  height: 16px;
  content: " ";
  background-color: #ffffff00;
  display: inline-block;
}

.radio-filtros input[type="radio"]:checked:after {
  margin-top: -1px;
  margin-left: -1px;
  border-radius: 17px;
  position: relative;
  width: 17px;
  height: 17px;
  content: " ";
  background-color: #000000;
  display: inline-block;
}

.boton_personalizado {
  height: 30px;
  width: 30px;
  border-radius: 20px;
}

.botonesTallas {
  width: 100%;
}

.bola {
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-repeat: no-repeat;
  margin: 5px;
}

.flechaFiltro {
  position: relative;
  top: 9px;
  left: 10px;
  height: 26px;
  width: 15px;
  display: flex;
}

.botonLimpiarFiltrosF {
  /* height: 2rem; */
  padding: 10px !important;
  /* margin-top: 20px !important; */
  /* margin-left: 40px !important; */
  /* margin-right: 40px !important; */
  color: #000000 !important;
  background-color: #dcdcdc;
  font-size: 1rem !important;
  border: solid 1px transparent !important;
}

.botonLimpiarFiltrosF:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.lineaFiltros {
  color: #000000;
  background-color: #000000;
  /* height: 1px; */
  border-color: #000000;
  margin-bottom: 0px;
  margin-top: 20px;
  margin-left: 1.8em;
  margin-right: 1.8em;
}

.filaFiltros {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10%;
}

.filaNew {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10%;
  margin-bottom: 5px;
}

.filaGenero {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  margin-left: 10%;
  margin-bottom: 5px;
}

.filaMarca {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
  margin-bottom: 5px;
}

.columnasMarcas {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10%;
}

.columnasCategorias {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
}

.filaTallas {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 7%;
  margin-bottom: 5px;
}

.filaColores {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 7%;
  margin-bottom: 5px;
}

.strikeNegro {
  position: relative;
  display: inline-block;
}

.strikeNegro:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background: black;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(25deg);
}

.strikeBlanco {
  position: relative;
  display: inline-block;
}

.strikeBlanco:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(25deg);
}

.form-check-label {
  margin-top: 0 !important;
}
