/*Mas de 1400px de ancho (Filtro Web)*/
@media (min-width: 1400px) {
  .tituloAyuda {
    font-size: 50px;
  }

  .tituloOpcion {
    font-size: 30px;
  }

  .imagenAyuda {
    margin: 50px;
  }

  .lineaAyuda {
    display: flex;
  }

  .imagenAlgodon {
    width: 100px;
    height: 100px;
  }

  .distanciaAyuda {
    margin: 50px;
  }

  .volver {
    position: absolute;
    top: 50%;
    left: 3%;
    font-size: 30px;
  }

  .botonIzquierda {
    margin-left: 10px;
    margin-top: 8px;
    height: 30px;
    width: 20px;
  }

  .imagenTransportadoras {
    margin: 10px;
    height: 100px;
    width: 100px;
    object-fit: contain;
  }

  .imagenTransportadorasLargas {
    margin: 10px;
    height: 100px;
    width: 200px;
    object-fit: contain;
  }

  .textoFooter {
    font-size: 15px;
    position: absolute;
    bottom: 0%;
  }

  .textoAyuda {
    width: 800px;
    font-size: 20px;
  }
}
