/*Mas de 1400px de ancho (Filtro Web)*/
@media (min-width: 1400px) {
  .formularioSesion {
    width: 20vw;
    margin-left: 40vw;
    font-size: 18px;
  }

  .volverIndex {
    position: absolute;
    top: 45%;
    transform: translate(0%, -45%);
    -webkit-transform: translate(0%, -45%);
    margin-left: 30px;
    font-size: 30px;
    color: black;
  }

  .registroLink {
    margin-left: 35%;
    width: 30%;
    padding: 5px !important;
    margin-top: 20px !important;
    color: #ffffff !important;
    background-color: #000000;
    font-size: 15px !important;
    border: solid 3px;
  }

  .registroLink:hover {
    color: #000000 !important;
    background-color: #00000000;
    border: solid 3px;
  }
}

/*Mas de 1400px de ancho (Filtro Web)*/
@media (max-width: 1400px) and (min-width: 800px) {
  .formularioSesion {
    width: 30vw;
    margin-left: 35vw;
    font-size: 18px;
  }

  .volverIndex {
    position: absolute;
    top: 45%;
    transform: translate(0%, -45%);
    -webkit-transform: translate(0%, -45%);
    margin-left: 30px;
    font-size: 30px;
    color: black;
  }

  .registroLink {
    margin-left: 35%;
    width: 30%;
    padding: 5px !important;
    margin-top: 20px !important;
    color: #ffffff !important;
    background-color: #000000;
    font-size: 15px !important;
    border: solid 3px;
  }

  .registroLink:hover {
    color: #000000 !important;
    background-color: #00000000;
    border: solid 3px;
  }
}
