/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */

#fila {
  width: 100%;
  position: relative;
  padding: 5%;
  padding-top: 1%;
  display: grid;
  grid-gap: 1rem;
  gap: 5rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}
/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  #fila {
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  }
  .Categoria {
    font-size: 17px;
    margin-bottom: 5px;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  #fila {
    gap: 4rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
  }
  .Categoria {
    font-size: 15px;
    margin-bottom: 5px;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  #fila {
    gap: 2rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  }
  .Categoria {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

/* @media (max-width: 1400px) {
  #fila {
    width: 100%;
    position: absolute;
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-rows: 40rem;
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}
@media (max-width: 1167px) {
  #fila {
    width: 100%;
    position: absolute;
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-rows: 40rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}

#fila {
  width: 100%;
  position: absolute;
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-auto-rows: 31rem;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
} */

.centerBlock {
  display: table;
  margin: auto;
}

.FuenteBebas {
  font-family: "Bebas";
}

.FuenteHelvetica {
  font-family: "Helvetica";
}

.sinMargenes {
  margin: 0;
  padding: 0;
}

.Categoria {
  font-size: 20px;
  margin-bottom: 5px;
}

.MercadoMinoristas {
  height: 100%;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 9%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tipoCompra {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.objetoTipoCompra {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.vertical-line-producto {
  width: 2px;
  background-color: black;
  height: 30px;
  float: left;
  z-index: 100;
  margin: 0px;
}

.headerAcordeon {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.giroFlechaArriba {
  -webkit-animation: 0.5s rotateArriba linear forwards;
  animation: 0.5s rotateArriba linear forwards;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.giroFlechaAbajo {
  -webkit-animation: 0.5s rotateAbajo linear;
  animation: 0.5s rotateAbajo linear;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@keyframes rotateArriba {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(540deg);
  }
}
@-webkit-keyframes rotateArriba {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(540deg);
  }
}

@keyframes rotateAbajo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateAbajo {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.flechaAcordeon {
  height: 10px;
  width: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
}

#circle span {
  position: fixed;
  width: 10px;
  height: 40px;
  bottom: 49px;
  right: 40px;
  text-align: center;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  transform-origin: bottom center;
}

.float {
  position: fixed;
  width: 51px;
  height: 51px;
  bottom: 24px;
  right: 19px;
  background-color: #25d366;
  color: #fff;
  border-radius: 33px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
}
.float:hover {
  text-decoration: none;
  color: #25d366;
  background-color: #fff;
}

.my-float {
  margin-top: 11px;
}
