/*Menos de 500px de alto(Tipo: Movil Normal Horizontal)*/
@media (max-height: 500px) {
  /* #productos {
    width: 90vw;
    height: auto;
    top: 10%;
    left: 5%;
    overflow-y: auto;
    overflow-x: hidden;

  } */

  .img-producto {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .carta {
    width: 95vw;
    height: 40vh;
    margin-left: 7%;
  }
  /* 
  .imgMedidas {
    width: 95vw;
    margin-bottom: 10px;
  } */

  #noHay {
    width: 70vw;
    height: 80vh;
    position: absolute;
    left: 7%;
  }

  #noHayMovil {
    display: none;
  }

  .ImgNoHayWeb {
    width: 90%;
    height: 90%;
  }

  .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }
}
