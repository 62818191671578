/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */
.Pedido {
  height: auto;
  width: 100%;
  position: absolute;
  top: 15%;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1%;
}

#paginas {
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}
.Paso1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
}
.flechaDerechaPedido {
  height: 26px;
  width: 15px;
  margin-left: 2%;
}

.cantidadPedido {
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: flex-start;
}
#titulosMovil {
  display: none;
}
#datosMovil {
  display: none;
}
.botonPaso1 {
  height: 10%;
  padding: 10px;
  color: #000000;
  font-size: 29px;
  border: solid 3px;
  margin: 2px;
}
.botonPaso2 {
  height: 10%;
  padding: 10px;
  color: #000000;
  font-size: 29px;
  border: solid 3px;
  margin: 2px;
}
.BarraTotal {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  position: fixed;
  width: 100%;
  font-size: 27px;
  bottom: 0px;
  border-top: black solid;
  background: white;
  z-index: 100;
}

#facturacion {
  border: solid 1px gray;
  font-size: 1.8rem;
  width: 150px;
  text-align: center;
}

.btn-add {
  height:45px;
  width:45px;
  background:black;
  color:white;
}

#remision {
  border: solid 1px gray;
  font-size: 1.8rem;
  width: 150px;
  text-align: center;
}

/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  .botonPaso1 {
    font-size: 27px;
  }
  .botonPaso2 {
    font-size: 27px;
  }
  .BarraTotal {
    font-size: 25px;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  #titulosMovil {
    display: table-header-group;
  }
  #titulosWeb {
    display: none;
  }

  #datosWeb {
    display: none;
  }
  #datosMovil {
    display: table-row;
  }
  .botonPaso1 {
    font-size: 25px;
  }
  .botonPaso2 {
    font-size: 25px;
  }
  .BarraTotal {
    font-size: 23px;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  .botonPaso1 {
    font-size: 23px;
    padding: 0px;
  }
  .botonPaso2 {
    font-size: 23px;
    padding: 0px;
  }
  .BarraTotal {
    font-size: 21px;
  }
}

@media (max-width: 440px) {
  .botonPaso1 {
    font-size: 21px;
  }
  .botonPaso2 {
    font-size: 21px;
  }
  .BarraTotal {
    font-size: 19px;
  }
}
