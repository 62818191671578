/*Menos de 1400px de ancho(Tipo: Movil Normal)*/
@media (max-width: 1000px) {
  #imagenCarousel {
    position: relative;
    height: 100vh;
    z-index: 0;
  }
  #botonCentral {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -45%);
    -webkit-transform: translate(-50%, -45%);
    object-fit: contain;
    color: white;
    border: 2px solid !important;
    font-size: 3.5em;
    height: 70px;
    line-height: 12%;
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
    z-index: 10;
  }

  #botonArriba {
    display: none;
  }

  #botonAbajo {
    display: none;
  }

  #MercadoWeb {
    display: none;
  }

  #Mayoristas {
    position: absolute;
    bottom: 15%;
    right: 15%;
    margin-left: 20px;
    font-size: 1.8em;
  }

  #Minoristas {
    position: absolute;
    bottom: 15%;
    left: 15%;
    font-size: 1.8em;
  }

  .indicadores {
    position: fixed;
    right: 3%;
    top: 41%;
    transform: translate(-3%, -41%);
    -webkit-transform: translate(-3%, -41%);
  }

  .radioMovil {
    margin-top: 20px;
  }

  #Buscanos {
    font-size: 2em;
    width: 210px;
    color: rgb(0, 0, 0);
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
    -webkit-transform: translate(-50%, -40%);
  }

  #BotonesRedes {
    margin-left: 10px;
    margin-right: 10px;
    height: 80%;
    width: 20%;
  }

  #ZonaCentral {
    position: absolute;
    top: 52%;
    left: 50%;
    width: 320px;
    height: 9%;
    transform: translate(-50%, -52%);
    display: flex;
  }
}
