/*Mas de 600px de ancho y menos de 850px de alto (Tipo: Tablet)*/
@media (min-width: 700px) and (max-height: 850px) {
  /* .Pedido {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    height: 80vh;
    width: 100vw;
    position: absolute;
    top: 12%;
  } */

  .atributosTabla {
    font-weight: bold;
    margin-top: 50px;
    font-size: 15px;
  }

  /* #listaProductos {
    position: absolute;
    top: 90px;
    width: 100%;
    height: 50%;
    overflow: auto;
  } */

  /* .botonPaso2 {
    position: absolute;
    bottom: 0.1%;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    width: 200px;
    height: 50px;
    padding-top: 5px !important;
    margin-top: 20px !important;
    color: #000000 !important;
    font-size: 25px !important;
    border: solid 3px;
  } */

  #imgEslogan {
    display: none;
  }

  /* .totalPedido {
    position: absolute;
    bottom: 0%;
    left: 20px;
    font-size: 28px;
    width: 280px;
    height: 60px;
    margin-top: 10px;
    margin-left: 10px;
  } */

  .botonPagina {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    border: 2px solid #000000;
    font-family: "Bebas";
    font-size: 25px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 0%;
  }

  .infoPaginas {
    font-family: "Helvetica";

    font-size: 15px;
    font-weight: bold;
    margin: 0px;
  }

  .botonBorrarPedido {
    height: 40px;
    width: 29px;
    cursor: pointer;
  }

  .tituloTabla {
    font-weight: bold;
    font-size: 25px;
    background-color: #ffffff;
    z-index: 100;
  }

  /*Formularios*/
  /* .Paso1 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 80%;
    margin-top: 50px;
  } */

  .Paso2 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 80vh;
    margin-top: 10%;
  }

  /*Formularios*/
  .Paso3 {
    overflow-x: hidden !important;
    overflow: auto;
    margin-top: 12%;
  }

  .formulariosCampo {
    overflow-x: hidden !important;
    overflow: auto;
    /* height: 40vh; */
  }

  .formularioDerecha {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioIzquierda {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioMitad {
    width: 50%;
    padding-left: 1px;
    padding-right: 1px;
  }

  .titulosPaso2 {
    font-size: 1.5em;
  }

  .textoPaso2 {
    font-size: 1em;
  }

  .formulario {
    width: 100%;
    font-size: 1.05em;
  }

  .fontSelect {
    font-size: 0.85em;
  }

  .bancos {
    margin-left: 20px;
    width: 60%;
  }

  .formularioWeb {
    display: none;
  }

  .formularioMovil {
    width: 100%;
  }

  #arrowAnim {
    display: none;
  }
}
