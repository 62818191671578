.botonLimpiarFiltros {
  height: 50px;
  padding-top: 5px !important;
  margin-top: 20px !important;
  margin-left: 40px !important;
  margin-right: 40px !important;
  color: #000000 !important;
  font-size: 25px !important;
  border: solid 3px;
}

.VideoTop {
  margin-top: 0px;
}

.Precios {
  font-size: 25px;
  font-weight: bold;
}
.ValuesPrecio {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
.cel {
  max-height: 473px;
  object-fit: contain;
  margin-top: 15px;
  border-radius: 20px;
}

.titles {
  text-align: inherit;
  font-size: 50px;
  font-weight: bold;
}
.parrafo {
  text-align: justify;
  font-size: 20px;
  font-weight: 400;
}

.Logos {
}

.pse {
}

.images {
  height: 60px;
}

.toplanding {
  margin-top: 200px;
}

.compra {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pagoM {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 12px;
}
@media only screen and (max-width: 1100px) {
  .botonLimpiarFiltros {
    height: 50px;
    padding-top: 5px !important;
    margin-top: 20px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
    color: #000000 !important;
    font-size: 22px !important;
    border: solid 3px;
  }

  .VideoTop {
    margin-top: -40px;
  }
  .Precios {
    font-size: 15px;
    font-weight: bold;
  }
  .ValuesPrecio {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
  }
}

@media only screen and (max-width: 650px) {
  .Precios {
    font-size: 15px;
    font-weight: bold;
  }
  .ValuesPrecio {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }
  .titles {
    text-align: inherit;
    font-size: 31px;
    font-weight: bold;
  }
  .Logos {
    width: inherit;
  }
  .pse {
    width: 40px;
  }
  .images {
    height: 35px;
  }
  .toplanding {
    margin-top: 100px;
  }
  .compra {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100px;
  }
  .pagoM {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 12px;
    width: 136px;
  }
}

@media only screen and (max-width: 350px) {
  .botonLimpiarFiltros {
    height: 50px;
    padding-top: 5px !important;
    margin-top: 20px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
    color: #000000 !important;
    font-size: 19px !important;
    border: solid 3px;
  }
  .Precios {
    font-size: 15px;
    font-weight: bold;
  }
}
