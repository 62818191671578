.CampoRegistro {
  position: absolute;
  top: 10%;
  width: 100%;
  height: 90%;
  overflow-y: auto;
}

.volverIndex {
  position: absolute;
  top: 15%;
  transform: translate(0%, -15%);
  -webkit-transform: translate(0%, -15%);
  margin-left: 15px;
  font-size: 25px;
  color: black;
}

.btn-Direccion {
  position: relative;
  width: 260px;
  left: 50%;
  transform: translate(-50%, -0%);
  -webkit-transform: translate(-50%, -0%);
  margin-bottom: 30px;
}

.formularioReg {
  display: flex;
}

.formColumn {
  margin: 30px;
  min-width: 38vw;
}

#sucursal {
  color: blue;
}

@media (max-width: 700px) {
  .formularioReg {
    flex-direction: column;
  }
}