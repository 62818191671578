/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

*::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

*::-webkit-scrollbar:vertical {
  width: 1vw !important;
}

*::-webkit-scrollbar-button:increment,
*::-webkit-scrollbar-button {
  display: none !important;
}

*::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

/* *::-webkit-scrollbar-thumb {
  background-color: #a7a7a7 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
} */

*::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

@font-face {
  font-family: 'Arial_Bold';
  src: url('../Fonts/Arial_Bold.ttf') format('truetype'),
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('../Fonts/Poppins-Light.ttf') format('truetype'),
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../Fonts/Poppins-Bold.ttf') format('truetype'),
}

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url('../Fonts/Poppins-SemiBoldItalic.ttf') format('truetype'),
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('../Fonts/CenturyGothic.ttf') format('truetype'),
}
