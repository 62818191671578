.spanError {
  color: red;
  font-size: small;
  margin-bottom: 20px;
}

input, select {
  margin-bottom: 0 !important;
}

label {
  margin-top: 20px;
}