#LineaPedido {
  color: #000000;
  background-color: #000000;
  height: 1px;
  border-color: #000000;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.noPadding {
  padding: 0 !important;
}

.noMargen {
  margin: 0 !important;
}

.LineaTotal {
  color: #000000;
  background-color: #000000;
  height: 1px;
  border-color: #000000;
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
}

.atributosTabla {
  font-weight: bold;
  margin-top: 50px;
}

.img-producto-pedido {
  width: 100px;
  height: 150px;
  margin-left: 0px;
  margin-right: 10px;
  padding-right: 0px;
}

.centrar {
  display: table;
  margin: auto;
}

.FuenteBebas {
  font-family: "Bebas";
}

.FuenteHelvetica {
  font-family: "Helvetica";
}

.SinDecoracionPedido {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  outline: none;
}

/* .botonPaso2 {
  color: #000 !important;
  background-color: #fff;
} */
.botonCupon {
  color: #000 !important;
  background-color: #fff;
  padding: 5px;
  max-width: 100px;
  max-height: 40px;
  border: solid 3px #000000;
  margin-left: 10px;
}

.botonCupon:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.botonPaso1:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.botonPaso2:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.botonPaso3:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

.Efecty:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}

th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  background-color: white !important;
}

#imgEslogan {
  position: absolute;
  bottom: 2.5%;
  left: 1%;
  height: 20px;
}

@font-face {
  font-family: "Bebas";
  src: url("../../../../static/Fonts/BEBASKAI.OTF");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("../../../../static/Fonts/HELVETICALTSTD-LIGHT.OTF");
  font-display: swap;
}

/*---------------------------------------------------------------------------------------------------------------------*/
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

form .form-info {
  padding: 10px 37px;
  margin: 5px 0;
}

.formulario select {
  margin-bottom: 30px;
}

.form-info input[type="text"],
[type="email"],
[type="date"],
[type="password"],
select,
textarea {
  border: none;
  border-bottom: solid 2px #474544;
  color: #474544;
  font-size: 0.8em;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 30px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.capitalizado {
  text-transform: capitalize;
}
input[type="text"]:focus,
[type="password"]:focus,
[type="email"]:focus,
textarea:focus {
  outline: none;
  padding: 0 0 0.875em 0;
}

#cupon_input {
  width: 200px;
}

.error {
  background-image: url(".././img/errorFormulario.png");
  background-position: 96.5% 0;
  background-repeat: no-repeat;
}

.correcto {
  background-image: url(".././img/correctoFormulario.png");
  background-position: 96.5% 0;
  background-repeat: no-repeat;
}

.errorFecha {
  background-image: url(".././img/errorFormulario.png");
  background-position: 95% 2px;
  background-repeat: no-repeat;
}

.correctoFecha {
  background-image: url(".././img/correctoFormulario.png");
  background-position: 95% 2px;
  background-repeat: no-repeat;
}

.imgPago {
  height: 70px;
  width: 170px;
  margin: 5px;
  padding: 10px;
  border-radius: 20px;
  border: solid 1px;
}

.imgPagoPSE {
  height: 80px;
  width: 80px;
  margin: 5px;
  padding: 10px;
  border-radius: 20px;
  border: solid 1px;
}

.imgPagoCredito {
  height: 90px;
  width: 200px;
  margin-top: 10px;
  margin-left: 30px;
  padding: 10px;
  border-radius: 20px;
  border: solid 1px;
}

.block1 {
  width: 15px;
  height: 120px;
  border-radius: 15px;
  background-color: black;
  border: 1px solid;
  margin-bottom: -50px;
  margin-left: 4px;
}

.block2 {
  width: 15px;
  height: 70px;
  border-radius: 18px;
  background-color: black;
  margin-bottom: -70px;
  border: 1px solid;
  margin-left: 22px;
  transform: rotate(-140deg);
}

.block3 {
  width: 15px;
  height: 70px;
  border-radius: 18px;
  background-color: black;
  border: 1px solid;
  margin-left: -15px;
  margin-bottom: 0px;
  transform: rotate(140deg);
}

.arrowSliding {
  position: absolute;
  -webkit-animation: slide 1.5s linear infinite;
  animation: slide 1.5s linear infinite;
}

@-webkit-keyframes slide {
  0% {
    top: 0px;
  }
  50% {
    top: 60px;
  }
  100% {
    top: 0px;
  }
}

@keyframes slide {
  0% {
    top: 0px;
  }
  50% {
    top: 60px;
  }
  100% {
    top: 0px;
  }
}
