:root {
  --white: #FFFFFF;
  --black: #000000;
  --very-light-pink: #C7C7C7;
  --text-input-field: #F7F7F7;
  --hospital-green: #ACD9B2;
  --sm: 14px;
  --md: 16px;
  --lg: 18px;
}
body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
}
.login {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}
.form-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 300px;
}
.logo {
  width: 50px;
  margin-bottom: 15px;
  justify-self: center;
  /* display: none; */
}
.title {
  font-size: var(--lg);
  margin-bottom: 12px;
  text-align: center;
}
.subtitle {
  color: var(--very-light-pink);
  font-size: var(--md);
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 32px;
  text-align: center;
}
.form {
  display: flex;
  flex-direction: column;
}
.label {
  font-size: var(--sm);
  font-weight: bold;
  margin-bottom: 4px;
}
.input {
  background-color: var(--text-input-field);
  border: none;
  border-radius: 8px;
  height: 30px;
  font-size: var(--md);
  padding: 6px;
  margin-bottom: 12px;
}
.primary-button {
  background-color: var(--hospital-green);
  border-radius: 8px;
  border: none;
  color: var(--white);
  width: 100%;
  cursor: pointer;
  font-size: var(--md);
  font-weight: bold;
  height: 50px;
}
.login-button {
  margin-top: 14px;
  margin-bottom: 30px;
}

.botonPaso2 {
    height: 50px;
    padding: 10px;
    color: #000000;
    font-size: 20px;
    border: solid 3px;
    margin: 2px;
  }

@media (max-width: 640px) {
  .logo {
    /* display: block; */
  }
}