.IniciarSesion {
  margin-top: 25vh;
}

.formularioSesion {
  width: 50vw;
  margin-left: 25vw;
  font-size: 18px;
}

.formulariosCampoSesion {
  overflow-x: hidden !important;
  overflow: auto;
  display: flex;
}

.formularioIndividual {
  margin-top: 50px;
  width: 100%;
  font-size: 1.05em;
}

.botonIngresar {
  margin-left: 25%;
  width: 50%;
  height: 50px;
  padding-top: 5px !important;
  margin-top: 20px !important;
  color: #000000 !important;
  font-size: 25px !important;
  border: solid 3px;
}

.botonIngresar:hover {
  color: #ffffff !important;
  background-color: #000000;
  border: solid 3px #000000;
}
