/*Menos de 1400px de ancho (Filtro Movil)*/
@media (max-width: 800px) {
  .formularioSesion {
    width: 75vw;
    margin-left: 12.5vw;
    font-size: 18px;
  }

  .volverIndex {
    position: absolute;
    top: 15%;
    transform: translate(0%, -15%);
    -webkit-transform: translate(0%, -15%);
    margin-left: 15px;
    font-size: 25px;
    color: black;
  }

  .registroLink {
    margin-left: 20%;
    width: 60%;
    padding: 5px !important;
    margin-top: 20px !important;
    color: #ffffff !important;
    background-color: #000000;
    font-size: 15px !important;
    border: solid 3px;
  }

  .registroLink:hover {
    color: #000000 !important;
    background-color: #00000000;
    border: solid 3px;
  }
}
