/*Mas de 600px de ancho y mas de 850px de alto (Tipo: Pc, Web)*/
/* .Pedido {
  height: auto;
  width: 99%;
  position: absolute;
  top: 15%;
} */
#listaProductos {
  position: relative;
  top: 10%;
  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: auto;
  z-index: -1;
}

.barraPaso3 {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  background: white;
  bottom: 0px;
  width: 100%;
}
.totalPedido {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 5px;
}

.botonPaso3 {
  height: 10%;
  padding: 10px;
  color: #000000;
  font-size: 20px;
  border: solid 3px;
  margin: 15px;
}

@media (min-width: 700px) {
  .atributosTabla {
    font-weight: bold;
    margin-top: 50px;
    font-size: 15px;
  }

  /*.botonPaso2 {
     position: absolute;
    bottom: 0.1%;
    right: 1%;
    width: 200px;
    height: 50px;
    padding-top: 5px !important;
    color: #000000 !important;
    margin-top: 20px !important;
    font-size: 25px !important;
    border: solid 3px;
  } */

  /* .botonPaso3 {
    position: absolute;
    bottom: 0.1%;
    right: 0.5%;
    transform: translate(-0.5%, 0%);
    -webkit-transform: translate(-0.5%, 0%);
    width: 200px;
    height: 50px;
    padding-top: 5px !important;
    margin-top: 20px !important;
    color: #000000 !important;
    font-size: 25px !important;
    border: solid 3px;
  } */

  .columnasBotonPaso {
    position: absolute !important;
    bottom: 0% !important;
    right: 0% !important;
    margin-right: 10px;
  }

  /* .totalPedido {
    position: absolute;
    top: 90%;
    left: 5%;
    right: 20px;
    font-size: 28px;
    width: 300px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
  } */

  .columnasTotalPedido {
    position: absolute !important;
    bottom: 7% !important;
    left: 0% !important;
    margin-left: 20px;
  }

  .botonPagina {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    border: 2px solid #000000;
    font-family: "Bebas";
    font-size: 1.3em;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 0%;
  }

  .infoPaginas {
    font-family: "Helvetica";

    font-size: 1em;
    font-weight: bold;
    margin: 0px;
  }

  .botonBorrarPedido {
    height: 40px;
    width: 29px;
    cursor: pointer;
  }

  .tituloTabla {
    font-weight: bold;
    font-size: 25px;
    background-color: #ffffff;
    z-index: 100;
  }

  /*Formularios*/
  .Paso2 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 80vh;
    margin-top: 4%;
  }

  .Paso3 {
    overflow-x: hidden !important;
    overflow: auto;
    margin-top: 4%;
  }

  .formulariosCampo {
    overflow-x: hidden !important;
    overflow-y: auto;
    display: flex;
    /* height: 45vh !important; */
  }

  .formularioDerecha {
    width: 35%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioIzquierda {
    width: 35%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioMitad {
    width: 50%;
    padding-left: 1px;
    padding-right: 1px;
  }

  .titulosPaso2 {
    font-size: 1.8em;
  }

  .textoPaso2 {
    font-size: 1em;
  }

  .formulario {
    width: 100%;
    font-size: 1.05em;
  }

  .fontSelect {
    font-size: 0.85em;
  }

  .bancos {
    margin-left: 20px;
    width: 30%;
  }

  .formularioMovil {
    display: none;
  }

  .formularioWeb {
    width: 100%;
    display: flex;
  }

  #arrowAnim {
    position: absolute;
    bottom: 250px;
    right: 70px;
    height: 50px;
    width: 50px;
  }
}
