.SinDecoracion {
  /*Fondo y decoracion*/
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
}

/*Derecha*/
#ZonaDerecha {
  position: absolute;
  top: 50%;
  right: 1%;
  width: 150px;
  transform: translate(-1%, -50%);
  display: flex;
}

#botonDerecha {
  margin-top: 7%;
  margin-right: 10px;
  height: 10%;
  width: 10%;
}

/*Izquierda*/
#ZonaIzquierda {
  position: absolute;
  top: 50%;
  left: 1%;
  width: 150px;
  transform: translate(-1%, -50%);
  display: flex;
  outline: none;
}

#ZonaIzquierda:hover #Minoristas,
#botonIzquierda {
  margin-right: 10px;
  margin-left: 10px;
}

#ZonaDerecha:hover #botonDerecha {
  margin-left: -10px;
}

#ZonaDerecha:hover #Mayoristas {
  margin-left: 15px;
}

#botonIzquierda {
  margin-top: 7%;
  margin-left: 10px;
  height: 10%;
  width: 10%;
}

/*---------------*/

.contenedor {
  position: relative;
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow: auto;
}

#imagenCarousel {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.snap {
  position: relative;
  scroll-snap-align: center;
}

.contenedor::-webkit-scrollbar {
  display: none;
}

.contenedor {
  scrollbar-width: none;
}

img {
  -webkit-select: none;
  -moz-select: none;
  -khtml-select: none;
  -ms-select: none;
}

span {
  -webkit-select: none;
  -moz-select: none;
  -khtml-select: none;
  -ms-select: none;
}

.marcasIndex {
  background: none;
  text-decoration: none;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 4px;
  transition: background-size 0.3s;
  background-color: #ffffff00;
  outline: none;
  text-decoration: none;
  padding: 0;
  border: 0;
}

.marcasIndex:focus {
  outline: none;
  text-decoration: none;
  padding: 0;
  border: 0;
}

.Spinner {
  background-color: rgb(4 4 4 / 50%);
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  top: 0;
  left: 0;
  position: absolute;
}

.posicionSpinner {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -35%);
  -webkit-transform: translate(-50%, -35%);
}

.posicionTextoSpinner {
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -65%);
  -webkit-transform: translate(-50%, -65%);
}

@keyframes wave {
  0% {
    background-position: 0 160px;
    background-size: 170px 300px;
  }

  100% {
    background-position: 500px -18px;
    background-size: 250px 150px;
  }
}

.loader {
  width: 120px;
  height: 120px;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  line-height: 120px;
  font-family: sans-serif;
  color: #ffffff;
  font-size: 16px;
}

.loader span {
  z-index: 3;
}

.loader-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: repeat-x 0 100px/150px 300px rgb(54, 86, 134);
  z-index: 2;
  animation: wave 5s ease-out forwards;
}
