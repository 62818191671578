/*Mas de 1400px de ancho (Filtro Web)*/
@media (min-width: 1000px) {
  #botonCentral {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    object-fit: contain;
    color: white;
    border: 2px solid !important;
    font-size: 6em;
    height: 100px;
    line-height: 12%;
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
    z-index: 10;
  }

  #botonCentral:before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 0px;
    background: rgba(0, 0, 0, 0.582);
    transition: all 0.5s ease;
    z-index: -10;
  }

  #botonCentral:hover:before {
    height: 100%;
  }

  .BotonCentralMovil {
    display: none;
  }

  #botonArriba {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -5%);
    -webkit-transform: translate(-50%, -5%);
    z-index: 1000;
    outline: none;
  }

  #botonAbajo {
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -5%);
    -webkit-transform: translate(-50%, -5%);

    outline: none;
  }

  #botonAbajo:hover {
    margin-bottom: 5px;
  }

  #botonArriba:hover {
    margin-top: 5px;
  }

  #Mayoristas {
    margin-right: 1%;
    font-size: 1.8em;
  }

  #Minoristas {
    margin-left: 10%;
    font-size: 1.8em;
  }

  #MercadoMovil {
    display: none;
  }

  .indicadores {
    position: fixed;
    right: 3%;
    bottom: 5%;
    transform: translate(-5%, -10%);
    -webkit-transform: translate(-5%, -10%);
  }

  #Buscanos {
    font-size: 5em;
    color: rgb(0, 0, 0);
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
    -webkit-transform: translate(-50%, -40%);
  }

  #BotonesRedes {
    margin-left: 10px;
    margin-right: 10px;
    height: 80%;
    width: 20%;
  }

  #ZonaCentral {
    position: absolute;
    top: 52%;
    left: 50%;
    height: 9%;
    transform: translate(-50%, -52%);
    display: flex;
  }
}

/* @media (min-width: 1000px) and (max-width: 1280px) {
  #imagenCarousel {
    position: relative;
    height: 110vh;
    width: max-content;
    z-index: 0;
  }
} */

@media (min-width: 1480px) {
  #imagenCarousel {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
}
