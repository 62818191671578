/* ESTILOS NORMALES (para escritorios muy grandes)
   ========================================================================= */
p.size {
  font-size: 20px !important;
  font-weight: 900;
}

.checkProduct {
  /* transform: scale(2); */
  appearance: none;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  width: 40px;
  height: 40px;
  appearance: none;
  border: 1px solid #888;
}

.checkProduct:checked {
  background-color: #55c96b;
}

.fichaProduct {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

/* RESPONSIVE
   ========================================================================= /
 
/ Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1199px) {
  p.size {
    font-size: 20px !important;
    font-weight: 900;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 767px) {
  p.size {
    font-size: 18px !important;
    font-weight: 900;
  }
}

/* Móviles en vertical
   ------------------------------------------------------------------------- */
@media (max-width: 480px) {
  p.size {
    font-size: 15px !important;
    font-weight: 900;
  }
}
