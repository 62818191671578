#FiltrosWeb {
  display: none;
}

#Buscar {
  display: none;
}

/* #BuscarFiltro {
  position: absolute;
  left: 33%;
  top: 1%;
  transform: translate(-50%, -0%);
  -webkit-transform: translate(-50%, -0%);
  text-align: center;
  display: flex;
} */

tro {
  background-color: #d8d8d800;
  border: none;
  border-bottom: 1.8px solid;
  font-size: 21px;
  outline: none;

  padding: 0;
  width: 150px;
}

/* #espacioLogo {
  position: absolute;
  top: 5%;
  left: 50%;
  display: flex;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
} */
/* 
#ImagenCroatta {
  height: 70px;
  width: 170px;
  object-fit: contain;
} */

.texto-Ayuda {
  display: none;
}

.texto-IniciarSesion {
  display: none;
}

.imagen-IniciarSesion {
  display: none !important;
}

#BotonInicioMenu {
  position: absolute;
  right: 0%;
  bottom: 0%;
}
div .cajaSesionMenu {
  position: absolute;
  top: -70px;
  left: -200px;
  width: 200px;
  height: 100px;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

/* .zoomBuscarMovil {
  transform: scale(0.6) !important;
  transition: transform 0.2s;
}

.zoomBuscarMovil:hover {
  transform: scale(1) !important;
} */

/* .lupaMovil {
  position: absolute;
  left: 100%;
  height: 105%;
  width: 17%;
  display: flex;
} */

.texto-AyudaFiltro {
  margin-top: 20%;
  margin-left: 20px;
  font-size: 20px;
}

.texto-IniciarSesionFiltro {
  margin-top: 20%;
  margin-right: 10px;
  font-size: 20px;
}

.carrito {
  position: absolute;
  left: 55%;
  top: 1%;
}

/* #ImagenBolsa {
  margin-left: 10px;
  margin-top: 5px;
  position: absolute;
  right: 1%;
  top: 1%;
  object-fit: contain;
  height: 35px;
  width: 37px;
} */

#Bolsa {
  position: absolute;
  height: 45px;
  width: 150px;
  margin-top: 17px;
  right: 1%;
  display: flex;
  font-size: 25px;
}

/* #CantidadCarrito {
  position: absolute;
  left: 70%;
  top: 15%;
  transform: translate(-67%, 0%);
  -webkit-transform: translate(-67%, 0%);
  font-weight: bold;
  margin-top: 10px;
  font-size: 15px;
} */

/* Mayoristas Seccion */
#ImagenCroattaMayoristas {
  height: 60px;
  width: 170px;
  object-fit: contain;
}

#espacioLogoMayoristas {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
}

#FiltrosMayoristas {
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255);
  z-index: 2000;
  transition: 1s;
  overflow-y: auto;
  overflow-x: hidden;
}

#MenuFiltrosMayoristas {
  margin-left: 10px;
  margin-top: 25px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  background-repeat: no-repeat;
}

#botonCerrarMayoristas {
  position: absolute;
  top: 2%;
  right: 7%;
  font-size: 1.5em;
  z-index: 2500;
}
