.imgPay {
    height: 47%;
    object-fit: scale-down;
}

.imgPayEfectivo {
    height: 265px;
    
}

@media (max-width: 960px) and (min-width: 201px) {
    .imgPayEfectivo {
        height: 130px;
        object-fit: contain;
        
    }
  }

.contentPay {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
  }
  
  .grid-item {

    text-align: center;
  }