.div-container {
  margin-bottom: 5rem;
  min-width: 62vw;
}

.div-container form div div {
  margin-bottom: 15px;
}

.div-container form div div input {
  margin-bottom: 0 !important;
}

.div-container form div div select {
  font-size: small;
  padding: 0;
}

.options {
  color: grey;
}

.div-container form div div span {
  color: red;
  font-size: small;
  margin: 0;
}

.form-display {
  display: flex;
  flex-direction: row;
}

.form-column {
  flex: 1;
  padding: 15px;
}

@media(max-width: 768px) {
  .form-display {
    flex-direction: column;
  }

  .form-column {
    padding: 10%;
  }
}