.ayudaWeb {
  width: 100%;
  margin-top: 15%;
  margin-bottom: 10%;
}

@media (max-width: 767px) {
  .ayudaWeb {
    width: 100%;
    margin-top: 25%;
    margin-bottom: 20%;
  }
}
@media (max-width: 1400px) {
  .tituloAyuda {
    font-size: 30px;
  }

  .tituloOpcion {
    font-size: 20px;
  }

  .imagenAyuda {
    margin: 10px;
    object-fit: contain;
    width: 50px;
    height: 50px;
  }

  .imagenAlgodon {
    width: 80px;
    height: 80px;
  }

  .distanciaAyuda {
    margin: 10px;
  }

  .volver {
    position: absolute;
    top: 15vh;
    left: 5%;
    font-size: 20px;
  }

  .botonIzquierda {
    margin-left: 5px;
    margin-top: 6px;
    height: 20px;
    width: 10px;
  }

  .imagenTransportadoras {
    margin: 10px;
    height: 40px;
    width: 40px;
    object-fit: contain;
  }

  .imagenTransportadorasLargas {
    margin: 10px;
    height: 40px;
    width: 70px;
    object-fit: contain;
  }

  .textoFooter {
    display: none;
  }

  .textoAyuda {
    width: 300px;
    font-size: 15px;
  }
}
