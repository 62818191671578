/*Menos de 600px de ancho y mas de 340 de alto(Tipo: Movil Normal)*/
@media (max-width: 800px) and (min-width: 340px) {
  /* #productos {
    width: 90vw;
    height: auto;
    top: 10%;
    left: 5%;
    overflow-y: auto;
    overflow-x: hidden;

  } */

  .img-producto {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .carta {
    width: 98vw;
    height: 40vh;
    margin: 1vw;
  }

  /* .imgMedidas {
    width: 95vw;
    margin-bottom: 10px;
  } */

  #noHayMovil {
    width: 90vw;
    height: 76vh;
    position: absolute;
    margin-top: 15px;
  }

  #noHayWeb {
    display: none;
  }

  .ImgNoHayMovil {
    width: 95%;
    height: 90%;
  }

  #WebGrande {
    display: none;
  }

  .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }
}
