/*Menos de 600px de alto y menos de 340px de ancho (Tipo: Movil Pequeño)*/
@media (max-width: 340px) {
  /* #productos {
    width: 90vw;
    height: auto;
    top: 10%;
    left: 5%;
    overflow-y: auto;
    overflow-x: hidden;

  } */

  .img-producto {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .carta {
    width: 98vw;
    height: 40vh;
    margin: 1vw;
  }

  /* .imgMedidas {
    width: 98vw;
    margin-bottom: 10px;
  } */

  div.vertical-line-producto {
    width: 2px;
    background-color: black;
    height: 30px;
    float: left;
    z-index: 100;
    margin: 5px;
  }

  #noHayMovil {
    position: absolute;
    margin-top: 15px;
    padding-right: 20px;
  }

  #noHayWeb {
    display: none;
  }

  .ImgNoHayMovil {
    margin-top: 20px;
    width: 98%;
    height: 100%;
  }

  #WebGrande {
    display: none;
  }

  .tipoCompra p {
    width: 50px;
    height: 30px;
    font-size: 12px;
    margin: 0px 5px 0px 5px;
  }
}
