/*Mas de 1400px de ancho (Filtro Web)*/
@media (min-width: 1400px) {
  .botonRegistro {
    margin-left: 37.5%;
    width: 25%;
    height: 50px;
    padding-top: 5px !important;
    margin-top: 10px !important;
    color: #000000 !important;
    font-size: 25px !important;
    border: solid 3px;
  }

  .botonRegistro:hover {
    color: #ffffff !important;
    background-color: #000000;
    border: solid 3px #000000;
  }

  .volverInicio {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    margin-left: 15px;
    font-size: 25px;
    color: black;
  }
}
