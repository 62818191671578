.ContenedorDetalle {
  height: 100%;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 11%;
  overflow-y: auto;
  overflow-x: hidden;
}
.row {
  width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}
.container {
  width: 100% !important;
}
.altura {
  height: 99vh !important;
  margin: 0px !important;
  padding: 0px !important;
  text-align: -webkit-center;
}

.ImgDetalle {
  height: 100%;
  width: 90%;
}
.imgMedidasDetalle {
  width: 75vw;
  margin-bottom: 10px;
}

.ImgRelacionados {
  height: 40vh;
  width: auto;
}
.carousel {
  width: 100% !important;
}
.lineaDetalle {
  color: #000000;
  background-color: #000000;
  height: 3px;
  border-color: #000000;
  margin-bottom: 0px;
  margin-top: 0px;
  /*margin-left: 20px;
    margin-right: 20px;*/
}

.FuenteBebas {
  font-family: "Bebas";
}

.TipografiaRel {
  font-size: 10px;
}

.TipografiaGeneral {
  font-size: 40px;
  margin: 0px;
}

.RelDatos {
  display: flex;
  justify-content: space-evenly;
  color: black;
  font-family: Bebas;
  height: 20px;
}

/* .imgMedidasDetalle {
  width: 21vw;
  margin-bottom: 20px;
} */

.TopFlecha {
  margin-bottom: 20px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-content: center;
  justify-content: center;
}
.letero {
  font-size: 26px;
}
.tipoCompra img {
  height: 39px;
  width: 39px;
  margin: 5px;
}
.detalleOculto2 {
  display: none;
}

.image-gallery {
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
}
.image-gallery-slide {
  height: 80vh;
  margin: 0px !important;
  padding: 0px !important;
}
.image-gallery-slide img {
  height: 80vh;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .RelDatos {
    display: flex;
    justify-content: space-evenly;
    color: black;
    font-family: Bebas;
    height: 20px;
    /*justify-content: space-between;
        width: 27vw;
        font-size: 10px;*/
  }

  .ImgDetalle {
    height: 100%;
    width: 100%;
  }
  /* .ContenedorDetalle {
    margin-top: 20%;
  } */
}

@media only screen and (max-width: 600px) {
  /* .ContenedorDetalle {
    margin-top: 23%;
  } */

  .RelDatos {
    display: flex;
    /*justify-content: space-evenly;*/
    color: black;
    font-family: Bebas;
    height: 25px;
    justify-content: space-between;
    width: 27vw;
    font-size: 11px;
    flex-direction: column;
  }

  .TopFlecha {
    /*margin-top: 102px;*/
    margin-bottom: 10px;
  }

  .TipografiaGeneral {
    font-size: 25px;
    margin: 0px;
  }

  .ImgDetalle {
    width: 100%;
  }
}

#myCarousel img {
  height: 90vh;
  width: auto;
  margin: 0px;
  display: contents;
}

#thumbSlider .carousel-inner {
  padding-left: 0rem;
  padding-right: 0rem;
}
#thumbSlider .carousel-inner .row {
  overflow: hidden;
}
#thumbSlider .carousel-inner .thumb:hover {
  cursor: pointer;
}
#thumbSlider .carousel-inner .thumb.active img {
  opacity: 1;
}
#thumbSlider .carousel-inner img {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  opacity: 0.5;
}
#thumbSlider .carousel-inner img:hover {
  opacity: 1;
}
#thumbSlider .carousel-inner .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20fill='%23000'%20viewBox='0%200%208%208'%3E%3Cpath%20d='M5.25%200l-4%204%204%204%201.5-1.5-2.5-2.5%202.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
#thumbSlider .carousel-inner .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20fill='%23000'%20viewBox='0%200%208%208'%3E%3Cpath%20d='M2.75%200l-1.5%201.5%202.5%202.5-2.5%202.5%201.5%201.5%204-4-4-4z'/%3E%3C/svg%3E");
}

@media (max-width: 767px) {
  .detalleOculto {
    display: none !important;
  }
  .detalleOculto2 {
    display: contents !important;
  }

  #thumbSlider .carousel-inner img {
    height: 20vh;
    width: auto;
    object-fit: contain;
  }
  #myCarousel img {
    height: 60vh;
    margin: 0;
  }
  .altura {
    height: 60vh !important;
  }

  .ImgRelacionados {
    height: 40vh;
    width: auto;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .ImgRelacionados {
    height: 20vh;
    width: auto;
  }
}
@media (max-width: 480px) {
  .ImgRelacionados {
    height: 20vh;
    width: auto;
  }
}
