.form-group input[type="text"],
[type="email"],
[type="date"],
[type="password"],
select,
textarea {
  border: none;
  border-bottom: solid 2px #474544;
  color: #474544;
  font-size: 0.8em;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 30px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.botones {
  background: black !important;
}

.textoTablas {
  font-size: 15px;
}
.Periodo {
  font-size: 30px;
}
.containedor {
  padding-top: 50vh;
  align-content: center !important;
}
.center_element {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 600px) {
  .textoTablas {
    font-size: 8px;
  }
  .Periodo {
    font-size: 20px;
  }

  .table td {
    padding: 4px;
    vertical-align: middle !important;
  }
  table.table th,
  table.table td,
  table.table tr {
    align-content: center;
    vertical-align: middle !important;
    text-align: center;
  }
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  .press {
    display: block; /* Remove bullet points; allow greater control of positioning */
    padding: 0; /* Override defaults for lists */
    margin: 0; /* Override defaults for lists */
    width: 100%; /* Get the row full width */
  }

  .press li {
    display: inline-block; /* Get all images to show in a row */
    width: 25%; /* Show 4 logos per row */
    text-align: center; /* Centre align the images */
  }

  @media (max-width: 960px) and (min-width: 501px) {
    .press li {
      width: 50%;
    } /* Show 2 logos per row on medium devices (tablets, phones in landscape) */
  }

  @media (max-width: 500px) {
    .press li {
      width: 100%;
    } /* On small screens, show one logo per row */
  }
  
  
}

@media (min-width: 1300px) {
  .container {
      max-width: 1340px !important;
    }
  }