/*Menos de 500px de alto(Tipo: Movil Normal Horizontal)*/
@media (max-height: 500px) {
  /* .Pedido {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    height: 80vh;
    width: 100vw;
    position: absolute;
    top: 12%;
  } */

  .atributosTabla {
    font-weight: bold;
    margin-top: 10px;
    font-size: 12px;
  }

  /* #listaProductos {
    position: absolute;
    top: 130px;
    width: 100%;
    height: 45%;
    overflow-y: auto;
    overflow-x: auto;
  } */

  /* .botonPaso2 {
    position: absolute;
    bottom: -3%;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    width: 45%;
    height: 35px;
    padding-bottom: 40px !important;
    color: #000000 !important;
    font-size: 150% !important;
    border: solid 3px;
  } */
  /* 
  .botonPaso3 {
    position: absolute;
    bottom: 0.1%;
    right: 0.5%;
    transform: translate(-0.5%, 0%);
    -webkit-transform: translate(-0.5%, 0%);
    width: 200px;
    height: 50px;
    padding-top: 5px !important;
    margin-top: 20px !important;
    color: #000000 !important;
    font-size: 25px !important;
    border: solid 3px;
  } */

  #imgEslogan {
    display: none;
  }

  /* .totalPedido {
    position: absolute;
    bottom: -3%;
    left: 1%;
    font-size: 1.5em;
    width: 40%;
    height: 50px;
    margin-left: 15px;
  } */
  /* 
  #paginas {

    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -12%);
    -webkit-transform: translate(-50%, -12%);
    display: flex;
    font-size: 1em;
    width: 100%;
    margin: 2%;
    background: white;
  } */

  .botonPagina {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: 2px solid #000000;
    font-family: "Bebas";
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 0%;
  }

  .infoPaginas {
    font-family: "Helvetica";

    font-size: 0.8em;
    font-weight: bold;
    margin: 0px;
  }

  .botonBorrarPedido {
    height: 40px;
    width: 29px;
    cursor: pointer;
  }

  .tituloTabla {
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    z-index: 100;
  }

  /*Formularios*/
  /* .Paso1 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 80%;
    margin-top: 2%;
  } */

  .Paso2 {
    overflow-x: hidden !important;
    overflow: auto;
    height: 50vh;
    margin-top: 20%;
  }

  .Paso3 {
    overflow-x: hidden !important;
    overflow: auto;
    margin-top: 20%;
  }

  .formulariosCampo {
    overflow-x: hidden !important;
    overflow: auto;
    /* height: 49vh; */
  }

  .formularioDerecha {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioIzquierda {
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .formularioMitad {
    width: 100%;
    padding-left: 1px;
    padding-right: 1px;
  }

  .titulosPaso2 {
    font-size: 1.05em;
  }

  .textoPaso2 {
    font-size: 0.8em;
  }

  .formulario {
    width: 100%;
    font-size: 1.05em;
  }

  .fontSelect {
    font-size: 0.85em;
  }

  .bancos {
    margin-left: 20px;
    width: 70%;
  }
  .formularioWeb {
    display: none;
  }

  .formularioMovil {
    width: 100%;
  }

  #arrowAnim {
    display: none;
  }
}
